import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './LoadingTranscribedText.css'; // Import your CSS file for styling

function ConversationMode() {
  const [transcribedText, setTranscribedText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [audioSrc, setAudioSrc] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const audioRef = useRef(null);

  // Handle recording
  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioChunksRef.current = [];
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => handleUpload();  // Automatically call handleUpload on stop
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  // Handle stopping recording
  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Handle upload and transcription
  const handleUpload = async () => {
    setLoading(true); // Start loading
    setTranscribedText('Processing audio...');

    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
    const formData = new FormData();
    formData.append('audio', audioBlob, 'recording.wav');

    try {
      const response = await axios.post(`/api/process`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
	//	responseType: 'blob', 
      }
	   );

      // Step 1: Display the transcribed text and translated text
      setTranscribedText(response.data.transcription);
	setTranslatedText(response.data.translatedText || ''); // Optional: translatedText
      /* Step 2: Play the translated audio (already part of the response)
      const audioBlob = new Blob([response.data], { type: 'audio/mp3' });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioSrc(audioUrl);
      audioRef.current.play();  // Auto-play the translated audio
*/

       // Step 2: Convert the base64 audio back into a Blob and play it
    const audioBlob = new Blob([new Uint8Array(atob(response.data.audioContent).split('').map(char => char.charCodeAt(0)))], { type: 'audio/mp3' });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioSrc(audioUrl);
	 // Step 3: Auto-play the audio
    if (audioRef.current) {
      audioRef.current.src = audioUrl;  // Set the audio source
      audioRef.current.play();  // Auto-play the audio
    }

    } catch (error) {
      console.error('Error processing audio:', error);
      setTranscribedText('Failed to process audio. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="Home">
      <nav>
        <Link to="/help" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
          Help 帮助 Ayuda
        </Link>
      </nav>
      
      {/* Recording Section */}
      <div>
        <h3>Please select your language to record:</h3>
        <button
          onClick={handleStartRecording}
          disabled={isRecording}
          style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
        >
          Start 开始
        </button>

        <button
          onClick={handleStopRecording}
          disabled={!isRecording}
          style={{ fontSize: '16px', padding: '10px 20px', margin: '10px' }}
          disabled={loading}
        >
          Stop Recording and Display 停 detener
        </button>
      </div>

      {/* Transcribed Text Section */}
      <div className="textarea-wrapper">
        <textarea
          value={transcribedText}
          onChange={(e) => setTranscribedText(e.target.value)}
          style={{ width: '100%', height: '100px', margin: '10px 0' }}
          disabled={loading} // Disable editing during processing
        />
        {loading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
            <p>Processing...请等待...espere por favor ...</p>
          </div>
        )}
      </div>

      {/* Translated Text Section */}
      <div>
        <h3>Translated Text:</h3>
        <p>{translatedText}</p>
      </div>

      {/* Audio Output Section */}
      {audioSrc && (
        <div>
          <audio ref={audioRef} controls src={audioSrc} autoPlay />
        </div>
      )}
<Link to="/EC" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
          Manual Mode 手动模式
        </Link>
      <Link to="/" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
        Back to Home 返回首页
      </Link>
    </div>
  );
}

export default ConversationMode;
