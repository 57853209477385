import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';  // Import axios for the IP logging request
import platform from 'platform';  // Import platform.js to get device, OS, and browser info

import Home from './Home';
import Help from './Help';
import EC from './EC';
import ES from './ES';
import CS from './CS';
import ConversationMode from './ConversationMode';
import ConversationModeES from './ConversationModeES';
import ConversationModeCS from './ConversationModeCS';

function App() {
  // useEffect to log the client's IP address when the app first loads
    useEffect(() => {
    const logClientInfo = async () => {
      try {
        // Get the platform information (device, OS, browser)
        const deviceInfo = {
          os: platform.os ? platform.os.toString() : 'Unknown',  // Check if platform.os exists
          browser: platform.name + ' ' + platform.version,  // Browser name and version
          device: platform.product || 'Unknown',  // Device name (iPhone, Android, etc.)
          userAgent: platform.ua,  // Full user agent string
          language: navigator.language,  // User's preferred language
        };

        // Send the device info to the backend
        await axios.post('/api/log-ip', { deviceInfo });

      } catch (error) {
        console.error('Error logging client info:', error);
      }
    };

    logClientInfo();  // Log the client info when the app loads
  }, []);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/help" element={<Help />} />
        <Route path="/ec" element={<EC />} />
        <Route path="/es" element={<ES />} />
        <Route path="/cs" element={<CS />} />
        <Route path="/ConversationMode" element={<ConversationMode />} />
        <Route path="/ConversationModeES" element={<ConversationModeES />} />
        <Route path="/ConversationModeCS" element={<ConversationModeCS />} />
      </Routes>
    </Router>
  );
}

export default App;
