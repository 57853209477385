import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="Home">
      <h1>Welcome to Multilanguage voice translator
	  欢迎 Bienvenida </h1>
      <nav>
        <Link to="/help" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
          Help 帮助 Ayuda
        </Link>
      </nav>
      <div>
        <h2>Choose your translation: 选择您的翻译： Elige tu traducción:</h2>
        <Link to="/ConversationMode" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
          English-中文
        </Link>
        <Link to="/ConversationModeES" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
          English-Española
        </Link>
        <Link to="/ConversationModeCS" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
          中文-Española
        </Link>
        
      </div>
    </div>
  );
}

export default Home;