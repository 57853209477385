import React from 'react';
import { Link } from 'react-router-dom';

function Help() {
  return (
    <div className="Help">
      <h1>How to use, 使用说明， Instrucciones de uso</h1>
      <p>Please select a language you are using to record first, after finish click to stop. Please don't record too long, no more than 10 sentences.  Check if the recording is correct, and then select a language you want to translate. You can choose conversation mode too. It will be more convenient in that way, but it may lose a little accuracy if the conversation too short as it needs to detect the language you are speaking.</p>
 <p>请先选择你使用的语言进行录音，完成后点击停止。请不要录制太长，不要超过10句。检查录音是否正确，然后选择要翻译的语言。你也可以选择对话模式。这样会更方便，但如果对话太短，可能会损失一点准确性，因为它需要检测你所说的语言。</p>
 <p>Seleccione primero el idioma que está utilizando para grabar y, después de terminar, haga clic para detener. No grabe demasiado tiempo, no más de 10 oraciones. Verifique si la grabación es correcta y luego seleccione el idioma que desea traducir. También puede elegir el modo de conversación. Será más conveniente de esa manera, pero puede perder un poco de precisión si la conversación es demasiado corta, ya que necesita detectar el idioma que está hablando.
</p>
      {/* Add more help content here */}
      <Link to="/" style={{ fontSize: '16px', padding: '10px 20px', margin: '10px', display: 'inline-block' }}>
        Back to Home 返回首页 Volver a Inicio
      </Link>
    </div>
  );
}

export default Help;